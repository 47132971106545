import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Alert } from "@nulogy/components";
import { alertConfig } from "../../playgrounds/alert";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Alert`}</h1>
    <p>{`An alert is a concise, event-driven message that communicates information and requires minimal interaction.`}</p>
    <Playground {...alertConfig} mdxType="Playground" />
    <h2>{`Variations`}</h2>
    <h3>{`Informative`}</h3>
    <p>{`Informative alerts provide a user with general messages and non-critical information.`}</p>
    <Example componentName="alert" scope={{
      Alert
    }} example="InformativeAlert" mdxType="Example" />
    <h3>{`Danger`}</h3>
    <p>{`Danger alerts inform users about errors that require attention.`}</p>
    <Example componentName="alert" scope={{
      Alert
    }} example="DangerAlert" mdxType="Example" />
    <h3>{`Success`}</h3>
    <p>{`Success alerts inform users that an action has been completed successfully.`}</p>
    <Example componentName="alert" scope={{
      Alert
    }} example="SuccessAlert" mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={alertConfig.props} mdxType="PropsTable" />
    <p>{`The Alert component has access to space style props. See the `}<inlineCode parentName="p">{`style`}</inlineCode>{` prop documentation for a full list of available props.`}</p>
    <h2>{`Content guidelines`}</h2>
    <p>{`To provide a more detailed explanation of the message that is being communicated, a link to the new screen can be provided in the child content using a Link component.`}</p>
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/toast"
        }}>{`Toast`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      